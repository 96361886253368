import React, { useState, useEffect, useContext } from "react";
import "./sidebarCSS/sidebar.css";
import { Avatar, Button, Tooltip } from "@mui/material";
import {
  AddCircleOutline,
  SearchRounded,
  Clear,
} from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import Divider from "@mui/material/Divider";
import axios from "axios";
import Sidebarchat from "./Sidebarchat";
import adminPFP from "./sidebarIMG/adminPFP.jpeg";
import { GlobalVariablesContext } from "../GlobalVariables";
import Direct_Mail from "../modals/Direct_Mail";
import Inbox from "../modals/Inbox";
import Reply from "../modals/Reply";
import toast from "react-hot-toast";
import { CiMail } from "react-icons/ci";
import { MdInbox } from "react-icons/md";






function Sidebar() {
  const [search, setSearch] = useState([]);
  const [input, setInput] = useState("");
  const [sidebarBool, setSidebarBool] = useState(true);
  const [rooms, setRooms] = useState([]);
  const [tmp, setTmp] = useState(0);
  const {setisLoggedIn2} = useContext(GlobalVariablesContext)
  const [loader, setLoader] = useState(false);

  // ---------------------------------------------
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [reply_mail, setReplyMail] = useState({});

  
  useEffect(() => {
    getRooms();
  }, [tmp]);

  useEffect(() => {
    if (rooms.length > 0) {
      setSearch(matcher(input, rooms));
    }
    if (input === "") {
      setSidebarBool(true);
    }
  }, [input]);

  const getRooms = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_ROOMS_API);
      const chatRooms = response.data.recent_messages.map((room) => ({
        id: room.id,
        name: room.profile_name,
        text: room.text,
        phone: room.phone_number,
        message_count: room.message_count,
        admin_unseen_count: room.admin_unseen_count,
        timestamp: room.timestamp,
        msg_status_code: room.msg_status_code,
        whatsapp_bussiness_number: room.whatsapp_bussiness_number,
      }));
      setRooms(chatRooms);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setTimeout(() => {
      setTmp((pre) => pre + 1);
    }, 6000);
  };

  const createChat = () => {
    const roomName = prompt("Please enter a name for the chat");
    const roomNumber = prompt("Please enter a number for the chat");
    
    if (roomName && roomName.length >= 20) {
      return alert("Please enter a shorter name for the room");
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer dummy_token",
    };

    if (roomName) {
      axios
        .post(
          process.env.REACT_APP_ROOMS_SENDMSG_API,
          {
            profile_name: roomName,
            message_text: "",
            phone_number: roomNumber,
          },
          { headers }
        )
        .then((response) => {
          alert("Added successfully !!!");
        })
        .catch((error) => {
          alert("Can not able to Add, Try again.");
        });
    }
  };

  const matcher = (s, values) => {
    const re = RegExp(`.*${s.toLowerCase().split("").join(".*")}.*`);
    return values.filter(
      (v) => v.name.toLowerCase().match(re) || v.phone.toLowerCase().match(re)
    );
  };

  const handleChange = (e) => {
    setSidebarBool(false);
    setInput(e.target.value);
  };
  
  function logout() {
    localStorage.removeItem("isLoggedIn2");
    setisLoggedIn2(false);
  }

  // --------------------------------------

  const handleOpen = (str, data) => {
    if(str === "custom_mail") {
      setOpen(true);  
    }
    else {
      setOpen2(true)
    }
  }

  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
    setOpen3(false);
  }

  const handleBackInbox = () => {
    setOpen2(true);
    setOpen3(false);
  }

  const handleOpenReply = async(data) => {
  // http://localhost:8000/api/get_mail_seen/<thread_id>

  try {
    const url = `${process.env.REACT_APP_GET_MAIL_SEEN}${data.thread_id}`;
    const response = await axios.get(url);
    // console.log(response.data);
    
  } catch (error) {
    toast.error("Failed to fetch emails");
  }
  setOpen2(false);
  setReplyMail(data)
  setOpen3(true);

  }


  
  return (
    <>
      <div className="Sidebar">
        <div className="Sidebar__header">
          <Avatar src={adminPFP} />
         <div>
         {/* <button 
          onClick={() => handleOpen("inbox")}
          style={{
            marginRight: "30px",
            border: "none",
            backgroundColor: "#90EE90",
            borderRadius: "5px",
            padding: "12px 12px",
            color: "black",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          Inbox
        </button>
        <button 
        onClick={() => handleOpen("custom_mail")}
          style={{
            marginRight: "30px",
            border: "none",
            backgroundColor: "#90EE90",
            borderRadius: "5px",
            padding: "12px 12px",
            color: "black",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          Custom Mail
        </button> */}
        <div style={{ display: "flex", gap: "10px", justifyContent: "center", alignItems: "center"  }}>
            <Tooltip title="Send Mail"  onClick={() => handleOpen("custom_mail")}>
              <Button startIcon={<CiMail  style={{ fontSize: "25px" , fontWeight: "bold"}} />}
              >
                Send Mail
              </Button>
            </Tooltip>
            <Tooltip title="Inbox"  onClick={() => handleOpen("inbox")}>
              <Button startIcon={<MdInbox style={{ fontSize: "25px" , fontWeight: "bold"}} />}
             >
                Inbox
              </Button>
            </Tooltip>
            
        </div>
        <Direct_Mail open={open} setOpen={setOpen} />
        <Inbox open2={open2} handleClose={handleClose} handleOpenReply={handleOpenReply}/>
        <Reply open3={open3} handleClose={handleClose} reply_mail={reply_mail} handleBackInbox={handleBackInbox} />

         </div>
      
          <div className="Sidebar__headerRight">
            <Tooltip title="Add Room">
              <AddCircleOutline onClick={createChat} />
            </Tooltip>

            <Tooltip title="Logout">
              <LogoutIcon onClick={logout} />
            </Tooltip>
          </div>
        </div>
        <Divider />
        <div className="Sidebar__search">
          <div className="Sidebar__searchContainer">
            <SearchRounded />
            <input
              placeholder="Search or Start New Chat"
              type="text"
              value={input}
              onChange={handleChange}
            />
            {input && (
              <Clear className="clear-icon" onClick={() => setInput("")} />
            )}
          </div>
        </div>
        <Divider />

        {rooms.length > 0 ? (
          sidebarBool ? (
            <div className="Sidebar__chats">
              {rooms.map((room, index) => (
                <div key={index}>
                  <Sidebarchat
                    addNewChat={true}
                    name={room.name}
                    text={room.text}
                    phone={room.phone}
                    message_count={room.message_count}
                    admin_unseen_count={room.admin_unseen_count}
                    timestamp={room.timestamp}
                    msg_status_code={room.msg_status_code?.toLowerCase()}
                    whatsapp_bussiness_number={room.whatsapp_bussiness_number}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="Sidebar__chats">
              {search.map((room, index) => (
                <div key={index}>
                  <Sidebarchat
                    addNewChat={true}
                    name={room.name}
                    text={room.text}
                    phone={room.phone}
                    message_count={room.message_count}
                    admin_unseen_count={room.admin_unseen_count}
                    timestamp={room.timestamp}
                    msg_status_code={room.msg_status_code?.toLowerCase()}
                    whatsapp_bussiness_number={room.whatsapp_bussiness_number}
                  />
                </div>
              ))}
            </div>
          )
        ) : (
          <div className="loading">
            <div className="gear"></div>
            <h2>LOADING CHATS</h2>
          </div>
        )}
      </div>
      <div className="selectMSG">SELECT USER TO VIEW MESSAGES</div>
    </>
  );
}

export default Sidebar;
