import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./modal.css";
import { FaArrowLeft } from "react-icons/fa";
import { CircularProgress } from "@mui/material";
import toast from "react-hot-toast";
import axios from "axios";
import { RxCrossCircled } from "react-icons/rx";


function Reply({open3, handleClose, reply_mail , handleBackInbox}) {

    const [body, setBody] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [to, setTo] = useState("");
  
    const handleDeleteFile = (index) => {
      setAttachments((prevAttachments) => 
        prevAttachments.filter((_, i) => i !== index)
      );
    };
  
    const handleFileChange = (e) => {
      const files = e.target.files;
      setAttachments([...attachments, ...files]);
    };
  
    const handleSend = async () => {
      try {
        setLoading(true);
        const baseUrl = process.env.REACT_APP_REPLY_DATA;
        const formData = new FormData();

        formData.append("mail_body", body);
        formData.append("t_id", reply_mail.thread_id);
        formData.append("selected_mail", reply_mail.selected_mail);
        formData.append("target_sub", reply_mail.subject);
        formData.append("target_address", reply_mail.from);

        console.log(body, reply_mail.thread_id, reply_mail.selected_mail, reply_mail.subject, reply_mail.from)

  
        // Append attachments
        attachments.forEach((file, index) => {
          formData.append(`attachments[${index}]`, file);
        });

        console.log(formData)
        // Send POST request with FormData
        const res = await axios.post(baseUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
  
        handleClose()
        setBody("");
        setAttachments([]);
        setLoading(false);
        toast.success("Email sent successfully");
      } catch (error) {
        setLoading(false);
        toast.error("Failed to send email");
      }
    };
  return (
    <Modal open ={open3} onClose={ () => handleClose()}    center
    classNames={{ modal: "customModal" }}>
  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <FaArrowLeft
          style={{ fontSize: "20px", cursor: "pointer", marginBottom: "20px" }}
         onClick={() => handleBackInbox()}
        />
        <h1 style={{ marginBottom: "20px", color: "#333" }}>Reply Email</h1>
      </div>
      <div className="reply-email-form">
        <div className="form-group">
          <label>Body</label>
          <textarea
            value={body}
            onChange={(e) => setBody(e.target.value)}
            rows="6"
          />
        </div>
        <div className="form-group">
          <label>Attachments</label>
          <div className="files">
            <input
              type="file"
              multiple
              accept="image/*,.pdf"
              onChange={handleFileChange}
             
            />
          </div>
        </div>
        <div className="attachments-list">
        {attachments.map((file, index) => (
          <div key={index} className="attachment-item">
            <span>{file.name}</span>
            
            <RxCrossCircled style={{ cursor: "pointer" , fontSize: "20px" , color: "red" }} onClick={() => handleDeleteFile(index)} />

          </div>
        ))}
      </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="primary" size={20} thickness={4} />
          </div>
        ) : (
          <button onClick={handleSend} className="send-btn">
            Send
          </button>
        )}
      </div>
    </Modal>
  )
}

export default Reply
