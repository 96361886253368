import React, { useEffect, useState, useContext } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./modal.css";
import axios from "axios";
import toast from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import { FaArrowLeft } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";


function Direct_Mail({open, setOpen}) {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toCC, setCC] = useState("");



  const handleDeleteFile = (index) => {
    setAttachments((prevAttachments) => 
      prevAttachments.filter((_, i) => i !== index)
    );
  };
  const handleFileChange = (e) => {
    const files = e.target.files;
    setAttachments([...attachments, ...files]);
  };

  const handleSend = async () => {
    const baseUrl = process.env.REACT_APP_SEND_MAIL_API;
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("mail_sub", subject);
      formData.append("selected_mail", from);
      formData.append("mail_body", body);
      formData.append("mail_to", to);
      formData.append("mail_cc", toCC);

      // Append attachments
      attachments.forEach((file, index) => {
        formData.append(`attachments[${index}]`, file);
      });

      const res = await axios.post(process.env.REACT_APP_DIRECT_MAIL_API, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // const response = await axios.post(
      //   process.env.REACT_APP_LAST_USED_MAIL_API,
      //   { [slected_num]: email }
      // );
      setLoading(false);
      toast.success("Email sent successfully");
      setOpen(false);
      setSubject("");
      setBody("");
      setAttachments([]);
      setTo("");
      setFrom("");
      
    } catch (error) {
    
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      center
      classNames={{ modal: "customModal" }}
    >
      <div style={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "space-between" }}>
        {/* <FaArrowLeft
          style={{ fontSize: "20px", cursor: "pointer", marginBottom: "20px" }}
          onClick={handleOpen}
        /> */}
        <h1 style={{ marginBottom: "20px", color: "#333" }}>
          Compose New Email
        </h1>
        <button
          onClick={() => setOpen(false)}
          className="modal_btn"
          onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor = "#0056b3")
          }
          onMouseOut={(e) =>
            (e.currentTarget.style.backgroundColor = "#007bff")
          }
        >
          close
        </button> 
      </div>

      <div className="reply-email-form">
        <div className="form-group">
          <label htmlFor="subject">From</label>
          <input
            type="text"
            id="subject"
            value={from}
            onChange={(e) => setFrom(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="subject">To</label>
          <input
            type="text"
            id="subject"
            value={to}
            onChange={(e) => setTo(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="subject">CC</label>
          <input
            type="text"
            id="subject"
            value={toCC}
            onChange={(e) => setCC(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="subject">Subject</label>
          <input
            type="text"
            id="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Body</label>
          <textarea
            value={body}
            onChange={(e) => setBody(e.target.value)}
            rows="6"
          />
        </div>

        <div className="form-group">
          <label>Attachments</label>
          <input
            type="file"
            multiple
            accept="image/*,.pdf"
            onChange={handleFileChange}
          />
        </div>
        <div className="attachments-list">
        {attachments.map((file, index) => (
          <div key={index} className="attachment-item">
            <span>{file.name}</span>
            
            <RxCrossCircled style={{ cursor: "pointer" , fontSize: "20px" , color: "red" }} onClick={() => handleDeleteFile(index)} />

          </div>
        ))}
      </div>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="primary" size={20} thickness={4} />
          </div>
        ) : (
          <button onClick={handleSend} className="send-btn">
            Send
          </button>
        )}
      </div>
    </Modal>
  );
}

export default Direct_Mail;
