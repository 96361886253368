import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Collapsible from "react-collapsible";
import "./modal.css";
import { IoIosRefresh } from "react-icons/io";
import { CircularProgress } from "@mui/material";
import { FaArrowLeft, FaFilePdf } from "react-icons/fa";
import { decode } from "base64-arraybuffer";
import toast from "react-hot-toast";

import axios from "axios";


function Inbox({ open2, handleClose, handleOpenReply }) {
  const [emails, setEmails] = useState([]);
  const [dataEmail, setDataEmail] = useState([]);
// console.log(dataEmail)
  
  const handleToggle = async (email) => {
    fetchEmailData(email.from);
    if (email.initial_state === "unseen") {
      try {
       
        const res = await axios.post(
          process.env.REACT_APP_SET_MAIL_SEEN,
          {
            [email.thread_id]: "seen",
          }
        );

        toast.success("Email marked as seen");
      } catch (error) {
        toast.error("Failed to fetch emails");
      }
    }
  };
  const fetchEmailData = async (email) => {
    try {
      // email = "aman.kt4@gmail.com";
      const respon = await axios.get(`${process.env.REACT_APP_INBOX_MAIL_DATA_API_DATA}${email}.json`);
      setDataEmail(respon.data.orders);
    } catch (error) {
      toast.error("Failed to User Data");
    }
  };

  const fetchEmails = async () => {
    setEmails([]);
    try {
      const respon = await axios.get(process.env.REACT_APP_INBOX_MAIL_API_DATA);
      setEmails(respon.data);
      toast.success("Emails fetched successfully");
    } catch (error) {
      toast.error("Failed to fetch emails");
    }
  };
  useEffect(() => {
    fetchEmails();
  }, []);


  const openPdfInNewTab = (base64pdf) => {
    const byteArray = decode(base64pdf);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const downloadImage = (base64image, filename) => {
    const byteArray = decode(base64image);
    const blob = new Blob([byteArray], { type: "image/*" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderEmail = (email, isReply = false) => (
    <div className={`email-content ${isReply ? "reply" : "main-email"}`}>
      <div className="email-header">
        <div>
          <strong>From:</strong> {email.from}
        </div>
        <div>
          <strong>To:</strong> {email.to.join(", ")}
        </div>
        {email.cc.length > 0 && (
          <div>
            <strong>CC:</strong> {email.cc.join(", ")}
          </div>
        )}
        {email.bcc.length > 0 && (
          <div>
            <strong>BCC:</strong> {email.bcc.join(", ")}
          </div>
        )}
        <div>
          <strong>Date:</strong> {email.date}
        </div>
      </div>
      <div
        className="email-main-body"
      >
        <div
          className="email-body"
          style={{
            flex: "1",
            paddingRight: "10px",
            backgroundColor: "#f0f0f0",
            borderRadius: "8px",
            padding: "10px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
          dangerouslySetInnerHTML={{ __html: email.body }}
        />


   {dataEmail && (
  <div
    className="email-orders-container"
    style={{
      display: "flex",
      width: "30%",
      flexDirection: "column",
      paddingLeft: "10px",
      borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      padding: "10px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      overflowY: "auto",
      maxHeight: "500px", // Adjust the height as needed
    }}
  >
    {dataEmail.map((order, index) => (
      <div
        key={index}
        className="order-box"
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "8px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#ffffff",
        }}
      >
        <strong>
          account_type:{" "}
          <span style={{ fontWeight: "normal" }}>
            {order.account_type}
          </span>
        </strong>
        <strong>
          address:{" "}
          <span style={{ fontWeight: "normal" }}>
            {order.address}
          </span>
        </strong>
        <strong>
          city:{" "}
          <span style={{ fontWeight: "normal" }}>
            {order.city}
          </span>
        </strong>
        <strong>
          date_Time:{" "}
          <span style={{ fontWeight: "normal" }}>
            {order.date_Time}
          </span>
        </strong>
        <strong>
          email:{" "}
          <span style={{ fontWeight: "normal" }}>
            {order.email}
          </span>
        </strong>
        <strong>
          fullname:{" "}
          <span style={{ fontWeight: "normal" }}>
            {order.fullname}
          </span>
        </strong>
        <strong>
          ip:{" "}
          <span style={{ fontWeight: "normal" }}>
            {order.ip}
          </span>
        </strong>
        <strong>
          mac_address:{" "}
          <span style={{ fontWeight: "normal" }}>
            {order.mac_address}
          </span>
        </strong>
        <strong>
          name:{" "}
          <span style={{ fontWeight: "normal" }}>
            {order.name}
          </span>
        </strong>
        <strong>
          order_id:{" "}
          <span style={{ fontWeight: "normal" }}>
            {order.order_id}
          </span>
        </strong>
        <strong>
          order_status:{" "}
          <span style={{ fontWeight: "normal" }}>
            {order.order_status}
          </span>
        </strong>
        <strong>
          payment_code:{" "}
          <span style={{ fontWeight: "normal" }}>
            {order.payment_code}
          </span>
        </strong>
        <strong>
          pincode:{" "}
          <span style={{ fontWeight: "normal" }}>
            {order.pincode}
          </span>
        </strong>
        <strong>
          Product-Name:{" "}
          <span style={{ fontWeight: "normal" }}>
            {order.products[0].model}
          </span>
        </strong>
        <strong>
        Product-Price :{" "}
          <span  style={{ fontWeight: "normal" }}>
            {order.products[0].price}
          </span>
        </strong>
        <strong>
  Random-ID:{" "}
  <span style={{ fontWeight: "normal" }}>
    {order.random_id}
  </span>
</strong>

<strong>
  Repeat Order:{" "}
  <span style={{ fontWeight: "normal" }}>
    {order.repeat_order}
  </span>
</strong>

<strong>
  state:{" "}
  <span style={{ fontWeight: "normal" }}>
    {order.state}
  </span>
</strong>

<strong>
  Telephone:{" "}
  <span style={{ fontWeight: "normal" }}>
    {order.telephone}
  </span>
</strong>

<strong>
Tracking Code:{" "}
  <span style={{ fontWeight: "normal" }}>
    {order.trackcode_date_time}
  </span>
</strong>

<strong>
  Tracking Link:{" "}
  <span style={{ fontWeight: "normal" }}>
    <a style={{ color: "blue"}} href={order.tracking_Link} target="_blank" >{order.tracking_Link}</a>
  </span>
</strong>

<strong>
  Tracking Number:{" "}
  <span style={{ fontWeight: "normal" }}>
    {order.tracking_number}
  </span>
</strong>

      </div>
    ))}


  </div>
)}

      </div>

      {email.attachments.length > 0 && (
        <div className="email-attachments">
          <strong>Attachments:</strong>
          <ul>
            {email.attachments.map((attachment, index) => {
              const isImage = attachment.content_type.startsWith("image/");
              const isPdf =
                attachment.content_type === "application/pdf" ||
                (attachment.content_type === "application/octet-stream" &&
                  attachment.filename.endsWith(".pdf"));
              const isOctetStream =
                attachment.content_type === "application/octet-stream";
              const isPng =
                isOctetStream && attachment.filename.endsWith(".png");
              const isJpg =
                isOctetStream && attachment.filename.endsWith(".jpg");
              const isJpeg =
                isOctetStream && attachment.filename.endsWith(".jpeg");
              const isGif =
                isOctetStream && attachment.filename.endsWith(".gif");

              const guessedContentType = isImage
                ? attachment.content_type
                : isPdf
                ? "application/pdf"
                : isPng || isJpg || isJpeg || isGif
                ? "image/" + attachment.filename.split(".").pop()
                : "application/octet-stream";
              const attachmentUrl = `data:${guessedContentType};base64,${attachment.content}`;

              return (
                <li
                  key={index}
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  {isImage || isPng || isJpg || isJpeg || isGif ? (
                    <button
                      onClick={() =>
                        downloadImage(attachment.content, attachment.filename)
                      }
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        background: "none",
                        border: "none",
                        color: "#007bff",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={attachmentUrl}
                        alt={attachment.filename}
                        style={{ maxWidth: "40%", height: "auto" }}
                      />
                    </button>
                  ) : isPdf ? (
                    <button
                      onClick={() => openPdfInNewTab(attachment.content)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        background: "none",
                        border: "none",
                        color: "#007bff",
                        cursor: "pointer",
                      }}
                    >
                      <FaFilePdf size={20} />
                      {attachment.filename}
                    </button>
                  ) : (
                    <a
                      href={attachmentUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {attachment.filename}
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {
        email.selected_mail !==  email.from &&
      
      <div style={{ textAlign: "center" }}>
        <button
          onClick={() => handleOpenReply(email)}
          className="modal_btn"
          onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor = "#0056b3")
          }
          onMouseOut={(e) =>
            (e.currentTarget.style.backgroundColor = "#007bff")
          }
        >
          Reply
        </button>
      </div>
}

      {email.replies.length > 0 && (
        <div className="replies">
          {email.replies.map((reply) => (
            <Collapsible
              key={reply.email_id}
              className="reply-item"
              trigger={
                <div className="collapsible-header reply-header">
                  {reply.subject} - {reply.date}
                </div>
              }
            >
              {renderEmail(reply, true)}
            </Collapsible>
          ))}
        </div>
      )}
    </div>
  );




  return (
    <Modal
      open={open2}
      onClose={() => handleClose()}
      style={{ textAlign: "center" }}
      classNames={{ modal: "customModal" }}
      center
    >
      <div
        className="modal-header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {/* <FaArrowLeft
            size={20}
            style={{ cursor: "pointer" }}
             
          /> */}
          <h1 style={{ color: "#333" }}>Inbox Mails</h1>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <button onClick={() => fetchEmails()} className="modal_btn">
          {" "}
          Refresh <IoIosRefresh size={16} />{" "}
        </button>
        <button
          onClick={() => handleClose()}
          className="modal_btn"
        >
          close
        </button>
        </div> 
      
      </div>

      {emails.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress color="primary" size={40} thickness={4} />
        </div>
      ) : (
        emails.map((email) => (
          <Collapsible
            className="element-item"
            trigger={
              <div
                style={{
                  backgroundColor:
                    email.initial_state === "seen" ? "#b0eeff" : "#FFCCCC",
                }}
                className="collapsible-header "
              >
                <div>{email.from.match(/^[^@]+/)[0]} -  {email.selected_mail}</div>
                <div>{email.subject}</div>
                <div style={{ fontSize: "12px", fontWeight: "normal" }}>
                  {email.date}
                </div>
              </div>
            }
            key={email.email_id}
            onOpening={() => handleToggle(email)}
          >
            {renderEmail(email)}
          </Collapsible>
        ))
      )}

           <button
          onClick={() => handleClose()}
          className="modal_btn"
          onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor = "#0056b3")
          }
          onMouseOut={(e) =>
            (e.currentTarget.style.backgroundColor = "#007bff")
          }
        >
          close
        </button> 
      
    </Modal>
  );
}

export default Inbox;
